body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root, .App, main {
  height: 100%;
  width: 100%;
  margin: 0;
}
.App {}
header {
  height: 50px;
  overflow: hidden;
  background-color: #071957;
  z-index: 999;
  width: 100%;
}

main {
  height: calc(100% - 50px);
  width: 100%;
  position: relative;
}

.header-left {
  float: left;
}
.header-right {
  float: right;
}
.header-right .menu {
  display: none;
}
.menu {
  padding:15px;
  color: #fff;
}
.menu a {
  text-decoration: none;
  color: #fff;
  display:inline-block;
  padding-left: 10px;
}
.menu a:hover {
  text-decoration: underline;
}

img.logo {
  padding: 10px;
}

.img-annotation-callout {
  text-align: center;
  font-size: .7em;
}

#results-focus:focus,
#results-focus a:focus,
button:focus,
#search button.menu:focus,
#site-search:focus,
#search-box button.search-mag:focus,
.close-icon2:focus,
.navigate-back:focus,
#menu-focus input:focus,
#directionButtonGoogle:focus,
#directionButton:focus,
#shareButton:focus,
#locationDescription a:focus {
  outline: 3px dotted #005FCC;
  box-shadow: 0px 0px 5px #fff;
}
.header-right .menu a:focus {
  outline: 3px dotted #fff;
  box-shadow: 0px 0px 5px #005FCC;
}

@media screen and (min-width: 768px) {
  .header-right .menu {
    display: inherit;
  }
  .header-right .menu a {
    padding-left: 10px;
    padding-right: 10px;
  }
}

#overlay {
  overflow-y: scroll;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
  -webkit-user-select: none;
          user-select: none;
  display: block;
  background: #fff;
  position: absolute;
  top: 10%;
  width: 100%;
  height: 100%;
}

button {
  background-color: #00009c;
  border: none;
  padding: 10px 20px;
  margin: 20px 0;
  color: #fff;
  font-size: .9em;
  text-transform: uppercase;
}

#drag-me {
  margin: 0 auto;
  width: 100%;
  color: #bbb;
  outline: none;
  background-color: transparent;
}

.hidden {
  display: none;
}

.close-icon2 {
  margin: 0;
  display:block;
  box-sizing:border-box;
  width:32px;
  height:32px;
  border: none;
  background-color: transparent;
  transition: all 0.3s ease;
  position: absolute;
  top: 25px;
  right: 15px;
  cursor: pointer;
}

.close-icon2:hover {
  outline: solid;
}

@media (min-width: 768px) {
  .close-icon2 {
    background: -webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%);
  }

  #overlay {
    display: block;
    background: #fff;
    position: absolute;
    top: 65px;
    width: 320px;
    left: 15px;
    height: 92%;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  }
}

#location-details {
  color: #262626;
  padding-bottom: 175px;
  overflow: auto;
}
#location-details .container {
  padding: 20px;
}

#location-details .route-filters {
  position: relative;
  left: -20px;
}

#location-details address {
  font-size: 16px;
  line-height: 28px;
}

h2 {
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  max-width: 90%;
  color: #012169;
}

@media (min-width: 768px) {
  #location-details {
    padding-bottom:50px;
  }
}

#overlay #building-icon img {
  width: 50px;
}

.Parking_link__26oRC {
  list-style: none;
  padding: 0;
  margin-top: -10px;
}
.Parking_link__26oRC button {
  border: none;
  color: blue;
  background-color: transparent;
  margin: 0;
  padding: 5px;
}
div.route-filters:last-child {
  margin-bottom: 120px;
}
div.route-filters h2 {
  margin-left: 20px;
  margin-top: 20px;
}
div.route-filters ul {
  border-bottom: 1px solid #9e9e9e;
  padding: 0 10px 10px 0;
}
div.route-filters ul li input[type=checkbox] {
  /* Double-sized Checkboxes */ /* IE */ /* FF */ /* Safari and Chrome */ /* Opera */
  transform: scale(1.3);
  margin-right: 10px;
}
div.route-filters ul li {
  list-style: none;
  margin: 2px 0;
  padding: 5px 0;
  overflow: hidden;
}

div.route-filters ul li span {
  padding:15px 4px;
  margin-right: 12px;
}
div.route-filters ul li div {
  margin-top: -15px;
  margin-left: 15px;
}

#directionButton, #directionButtonGoogle {
  cursor: pointer;
  margin: 10px 10px 10px 0;
  margin-right: 10px;
  height: 36px;
  border-radius: 10px;
  border: solid 1px #979797;
  background-color: #0577b1;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -0.1px;
}
#directionButton:active,
#directionButtonGoogle:active,
#directionButton:hover,
#directionButtonGoogle:hover,
#directionButton:focus,
#directionButtonGoogle:focus {
  background-color: #012169;
}

#shareButton {
  cursor: pointer;
  margin: 10px 0;
  border-radius: 10px;
  border: solid 1px #979797;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.1px;
  color: #0577b1;
  text-transform: none;
}
#shareButton:active,
#shareButton:hover,
#shareButton:focus {
  background-color: #efeff0;
  color: #012169;
}

#location-phone a {
  color: #000;
  text-decoration: none;
}

#location-openstatus span.open {
  color: #008800;
}
#location-openstatus span.closed {
  color: #cc3300;
}

.collapsed {
  visibility: hidden;
  display: none;
}
.expanded {
  display: block;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
#location-expandable button:hover {
  background-color: #efeff0;
}
#location-expandable button {
  min-height: 60px;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  cursor: pointer;
  background-color: transparent;
  color: #262626;
  font-size: 16px;
  border-bottom: 1px solid #efeff0;
}
#location-expandable div:first-child {
  border-top: 1px solid #efeff0;
}
#location-expandable button[aria-expanded="true"] {
  background-color: #efeff0;
}

#location-expandable button img {
  max-width: 18px;
  max-height: 30px;
  padding: 0 10px 0 0;
}

#location-expandable.building button span {
  margin-top: 12px;
}

#hours ul {
  margin-top: -10px;
}
.list-decor-none {
  list-style:none;
  padding-left: 0;
}

#description {
  margin-top: -20px;
}

#overlay img {
  padding: 10px 10px 10px 0;
  margin: 5px 0;
  width: 100%;
}

#locationDescription {
  clear: both;
}

#locationDescription span {
  margin-bottom: 10px;
}

#dining-btn ul {
  margin-top: -10px;
}
#dining-btn li {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;

}
#dining-btn li a {
  text-decoration: none;
  color: #262626;
}
.dining-open {
  color: #008800;
  float: right;
}
.dining-closed {
  color: #cc3300;
  float: right;
}

@media (min-width: 768px) {}

.DirectionsSearchField_creatable__2-GnL {
  border: none;
  width: 180px;
  display: inline-block;
}
.DirectionsSearchField_creatable__2-GnL span {
  background-color: transparent !important;
}
.duke__control {
  border: none !important;
}
.duke__value-container {
  padding:0;
}
.duke__indicators {
  max-width: 30px;
  margin-right: 15px;
}
.duke__value-container--has-value {}
#directions-form {
	width: 100%;
	margin-top: 20px;
	padding-bottom: 5px;
	border-bottom: 2px solid #bbb;
}

#directions-form button {
	background-color: transparent;
}

#directions-form button.direction-type {
	padding: 5px;
	margin-bottom: 0;
	margin-top: 10px;
}
button::-moz-focus-inner {
	border: 0;
}

#directions-form button.swap-img {
	float: right;
	margin: 40px 5px;
	padding: 0;
}
#directions-form button img {
	padding: 0;
}

.inputs {
	width: 240px;
	display: inline-block;
}

#directions-form label span {
	display: inline-block;
	color: #666;
	width: 50px;
	padding-top: 5px;
}
#directions-form label {
	display: block;
	margin-top: 10px;
	line-height: 14px;
	width: 100%;
	border-bottom: 2px solid #bbb;
}

#directions-form input {
	border: none;
	padding: 5px;
	width: 180px;
	display: inline-block;
}
#directions h2 {
	font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.13px;
  color: #012169;
}
#directions span.final-destination {
	height: 40px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #012169;
}
#directions address {
	font-family: OpenSans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #262626;
}
.leg {
	margin: 30px auto;
}
.direction-leg-details p {
	font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #262626;
}
.direction-leg-details .footing span.distance {
	font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #666666;
  float: left;
  margin-right: 10px;
}
.direction-leg-details .footing hr {
	position: relative;
	top:7px;
}
#sharing {
	margin-top: 20px;;
}
#sharing label span {
	display: block;
	margin: 10px auto 5px;
}
#sharing input {
	width: 275px;
}

#location-details span.arrival-time {
  text-align: right;
  width: 100%;
  display: inline-block;
}

#location-details div.arrival-route {
  margin-right: -20px;
  margin-left: -20px;
  border-bottom: 1px solid #999;
  padding-bottom:1px;
  padding-top: 1px;
  display: block;
  height: 100%;
}

#location-details div.arrival-route .container {
  padding-top:5px;
}

#location-details div.arrival-route h3 {
  margin: 5px auto;
}
ul.search-results {
  list-style: none;
  padding: 0;
}

ul.search-results li {
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid;
}

ul.search-results li a {
  text-decoration: none;
  color: #000;
}

ul.search-results li:hover {
  background-color: #cdcdcd;
}
#map-container,
#map {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: relative;
}

@keyframes spin {
  100% { transform:rotate(45deg) }
}
#search {
  position: absolute;
  z-index: 1;
  top:20px;
  margin: 0 auto;
  width: 80%;
  left: 10%;
}

#hamburger {
  height: 50px;
  width: 50px;
  margin: 0;
  float: left;
}

#search-box {
  display: inline-block;
  height: 50px;
  width: 100%;
  background-color: #fafafa;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}
#search-box button {
  margin: 0;
  margin-right: 10px;
  height: 50px;
  width: 50px;
  background-color: transparent;
  float: right;
  padding: 0;
  background-color: #fafafa;
}
#search-box button.search-mag {
  width: 40px;
}
#search-box button.search-mag img {
  padding-left: 10px;
}
#search button.menu {
  margin:0;
  float: left;
  background-color: #071957;
}
#search button.menu:hover, #search button.menu:focus {
  background-color: #0577b1;
}
#search-box button img {
  height: 24px;
}
#search-box input {
  height: 30px;
  color: #666666;
  background-color: #fafafa;
  border: none;
  padding: 1px 10px;
  margin: 10px 5px;
  width: calc(100% - 110px);
}

/* placeholder text white */
::-webkit-input-placeholder {
  color: #666666;
  font-size: 16px;
}
::-moz-placeholder {
  color: #666666;
  font-size: 16px;
}
:-ms-input-placeholder {
  color: #666666;
  font-size: 16px;
}
:-moz-placeholder {
  color: #666666;
  font-size: 16px;
}

/* hide the scroll bar */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #FF0000;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  width: 10px;
  margin-top:-15px;
  height: 10px;
  color:#fafafa;
}
input[type="search"] {
  /*-webkit-appearance: none;*/
  /*background-color: green;*/
}

input, select, textarea {
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  #search {
    width: 320px;
    left: 15px;
    top: 15px;
  }
  #search-box input {
    width: 205px;
  }
}

#search-results {
	background: white;
	position: relative;
	z-index: 2;
	border-top: 1px solid #eee;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
	overflow-y: auto;
	list-style: none;
	max-height: 300px;
 box-shadow: 0 3px 3px 0 #eee;
}

#search-results li {
	padding: 5px;
}
#search-results li:focus {
	background-color: #e5e5e5;
	outline: none;
}
.hide-me {
	visibility: hidden;
}
aside {
  height: 100%;
  display: block;
  float: left;
  position: fixed;
  z-index: 3;
  top: 0;
  background-color: #fff;
  box-shadow: 10px 0 5px -5px #999;
}

aside section {
  height: 100%;
  margin-top: 80px;
  border-top: 1px solid #9e9e9e;
}

button.navigate-back {
  color: #666;
  background-color: transparent;
  position: absolute;
  display: block;
  top:0;
  cursor: pointer;
}

button.navigate-back img {
  float: left;
}

button.navigate-back span {
  position: relative;
  top: 5px;
  left: 10px;
}

aside.opened {
  width: 100%;
  transition: all 0.5s ease;
}
aside.closed {
  width: 0;
  padding:0;
  transition: all 0.5s ease;
}

aside.opened .lists {
  height: 100%;
  overflow-y: auto;
  display: block;
}

@media (min-width: 768px) {
  aside.opened {
    width: 330px;
  }
}

.left-arrow {
  transform: rotate(-180deg);
}
.menu-filters:last-child {
  margin-bottom:120px;
}
.menu-filters {
  border-bottom: 1px solid #9e9e9e;
  display: block;
  width: 100%;
  min-width: 260px;
  overflow: hidden;
}
.menu-filters h1 {
  margin-left: 16px;
  margin-top: 16px;
}
.menu-filters ul.shown {
  margin-right: -8px;
}

.menu-filters ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding:10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.menu-filters ul li {
  font-size: 14px;
  color: #666;
}
.menu-filters ul li input {
  position: relative;
  top: 4px;
}
.menu-filters .heading {
  text-transform: inherit;
  padding-top: 15px;
  margin: 2px auto;
  width: 90%;
  min-width: 250px;
  text-align: left;
  background-color: transparent;
  color: #666;
  font-size: 14px;  
}
.menu-filters .heading img {
  height: 24px;
  vertical-align: middle;
  margin-right: 38px;
  margin-left: 5px;
  margin-bottom: -8px;
}
.menu-filters button {
  text-transform: inherit;
  padding-top: 5px;
  margin:2px auto;
  width: 90%;
  min-width: 250px;
  text-align: left;
  background-color: transparent;
  color: #666;
  cursor: pointer;
  font-size: 14px;
}
.menu-filters button div {
  width: 60px;
  display: inline-block;
}
.menu-filters button img {
  height: 24px;
  vertical-align: middle;
}
.menu-filters button span {
  vertical-align: middle;
}
.menu-filters button.active {
  color: #262626;
}
.menu-filters .right-arrow {
  float: right;
  height: 16px;
  padding-top: 6px;
}
.menu-filters .right-arrow.down {
  transform: rotate(-270deg);
}
.menu-filters button:hover {
  background-color: #cdcdcd;
  color: #000;
}


#mobile-app-cta {
  position: fixed;
  z-index: 999;
  background: #fff;
  top: 35%;
  width: 250px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 20px 20px 0;
}

#mobile-app-cta button {
  float: right;
  background-color: transparent;
  color: #0577b1;
  line-height: 16px;
  font-size: 13px;
  border:none;
}

#mobile-app-cta button:active {
  background-color: rgba(5, 119, 177, 0.2);
}

