.link {
  list-style: none;
  padding: 0;
  margin-top: -10px;
}
.link button {
  border: none;
  color: blue;
  background-color: transparent;
  margin: 0;
  padding: 5px;
}