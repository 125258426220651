html, body, #root, .App, main {
  height: 100%;
  width: 100%;
  margin: 0;
}
.App {}
header {
  height: 50px;
  overflow: hidden;
  background-color: #071957;
  z-index: 999;
  width: 100%;
}

main {
  height: calc(100% - 50px);
  width: 100%;
  position: relative;
}

.header-left {
  float: left;
}
.header-right {
  float: right;
}
.header-right .menu {
  display: none;
}
.menu {
  padding:15px;
  color: #fff;
}
.menu a {
  text-decoration: none;
  color: #fff;
  display:inline-block;
  padding-left: 10px;
}
.menu a:hover {
  text-decoration: underline;
}

img.logo {
  padding: 10px;
}

.img-annotation-callout {
  text-align: center;
  font-size: .7em;
}

#results-focus:focus,
#results-focus a:focus,
button:focus,
#search button.menu:focus,
#site-search:focus,
#search-box button.search-mag:focus,
.close-icon2:focus,
.navigate-back:focus,
#menu-focus input:focus,
#directionButtonGoogle:focus,
#directionButton:focus,
#shareButton:focus,
#locationDescription a:focus {
  outline: 3px dotted #005FCC;
  box-shadow: 0px 0px 5px #fff;
}
.header-right .menu a:focus {
  outline: 3px dotted #fff;
  box-shadow: 0px 0px 5px #005FCC;
}

@media screen and (min-width: 768px) {
  .header-right .menu {
    display: inherit;
  }
  .header-right .menu a {
    padding-left: 10px;
    padding-right: 10px;
  }
}
