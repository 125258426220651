#shareButton {
  cursor: pointer;
  margin: 10px 0;
  border-radius: 10px;
  border: solid 1px #979797;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.1px;
  color: #0577b1;
  text-transform: none;
}
#shareButton:active,
#shareButton:hover,
#shareButton:focus {
  background-color: #efeff0;
  color: #012169;
}
