#sharing {
	margin-top: 20px;;
}
#sharing label span {
	display: block;
	margin: 10px auto 5px;
}
#sharing input {
	width: 275px;
}
