.menu-filters:last-child {
  margin-bottom:120px;
}
.menu-filters {
  border-bottom: 1px solid #9e9e9e;
  display: block;
  width: 100%;
  min-width: 260px;
  overflow: hidden;
}
.menu-filters h1 {
  margin-left: 16px;
  margin-top: 16px;
}
.menu-filters ul.shown {
  margin-right: -8px;
}

.menu-filters ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding:10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.menu-filters ul li {
  font-size: 14px;
  color: #666;
}
.menu-filters ul li input {
  position: relative;
  top: 4px;
}
.menu-filters .heading {
  text-transform: inherit;
  padding-top: 15px;
  margin: 2px auto;
  width: 90%;
  min-width: 250px;
  text-align: left;
  background-color: transparent;
  color: #666;
  font-size: 14px;  
}
.menu-filters .heading img {
  height: 24px;
  vertical-align: middle;
  margin-right: 38px;
  margin-left: 5px;
  margin-bottom: -8px;
}
.menu-filters button {
  text-transform: inherit;
  padding-top: 5px;
  margin:2px auto;
  width: 90%;
  min-width: 250px;
  text-align: left;
  background-color: transparent;
  color: #666;
  cursor: pointer;
  font-size: 14px;
}
.menu-filters button div {
  width: 60px;
  display: inline-block;
}
.menu-filters button img {
  height: 24px;
  vertical-align: middle;
}
.menu-filters button span {
  vertical-align: middle;
}
.menu-filters button.active {
  color: #262626;
}
.menu-filters .right-arrow {
  float: right;
  height: 16px;
  padding-top: 6px;
}
.menu-filters .right-arrow.down {
  transform: rotate(-270deg);
}
.menu-filters button:hover {
  background-color: #cdcdcd;
  color: #000;
}

