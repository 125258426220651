#location-details span.arrival-time {
  text-align: right;
  width: 100%;
  display: inline-block;
}

#location-details div.arrival-route {
  margin-right: -20px;
  margin-left: -20px;
  border-bottom: 1px solid #999;
  padding-bottom:1px;
  padding-top: 1px;
  display: block;
  height: 100%;
}

#location-details div.arrival-route .container {
  padding-top:5px;
}

#location-details div.arrival-route h3 {
  margin: 5px auto;
}