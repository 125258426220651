#dining-btn ul {
  margin-top: -10px;
}
#dining-btn li {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;

}
#dining-btn li a {
  text-decoration: none;
  color: #262626;
}
.dining-open {
  color: #008800;
  float: right;
}
.dining-closed {
  color: #cc3300;
  float: right;
}

@media (min-width: 768px) {}
