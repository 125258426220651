#location-details {
  color: #262626;
  padding-bottom: 175px;
  overflow: auto;
}
#location-details .container {
  padding: 20px;
}

#location-details .route-filters {
  position: relative;
  left: -20px;
}

#location-details address {
  font-size: 16px;
  line-height: 28px;
}

h2 {
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  max-width: 90%;
  color: #012169;
}

@media (min-width: 768px) {
  #location-details {
    padding-bottom:50px;
  }
}
