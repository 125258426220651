div.route-filters:last-child {
  margin-bottom: 120px;
}
div.route-filters h2 {
  margin-left: 20px;
  margin-top: 20px;
}
div.route-filters ul {
  border-bottom: 1px solid #9e9e9e;
  padding: 0 10px 10px 0;
}
div.route-filters ul li input[type=checkbox] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.3); /* IE */
  -moz-transform: scale(1.3); /* FF */
  -webkit-transform: scale(1.3); /* Safari and Chrome */
  -o-transform: scale(1.3); /* Opera */
  transform: scale(1.3);
  margin-right: 10px;
}
div.route-filters ul li {
  list-style: none;
  margin: 2px 0;
  padding: 5px 0;
  overflow: hidden;
}

div.route-filters ul li span {
  padding:15px 4px;
  margin-right: 12px;
}
div.route-filters ul li div {
  margin-top: -15px;
  margin-left: 15px;
}