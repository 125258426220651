aside {
  height: 100%;
  display: block;
  float: left;
  position: fixed;
  z-index: 3;
  top: 0;
  background-color: #fff;
  -webkit-box-shadow: 10px 0 5px -2px #999;
  box-shadow: 10px 0 5px -5px #999;
}

aside section {
  height: 100%;
  margin-top: 80px;
  border-top: 1px solid #9e9e9e;
}

button.navigate-back {
  color: #666;
  background-color: transparent;
  position: absolute;
  display: block;
  top:0;
  cursor: pointer;
}

button.navigate-back img {
  float: left;
}

button.navigate-back span {
  position: relative;
  top: 5px;
  left: 10px;
}

aside.opened {
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
aside.closed {
  width: 0;
  padding:0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

aside.opened .lists {
  height: 100%;
  overflow-y: auto;
  display: block;
}

@media (min-width: 768px) {
  aside.opened {
    width: 330px;
  }
}

.left-arrow {
  transform: rotate(-180deg);
}