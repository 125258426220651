#search-results {
	background: white;
	position: relative;
	z-index: 2;
	border-top: 1px solid #eee;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0;
	overflow-y: auto;
	list-style: none;
	max-height: 300px;
	-webkit-box-shadow: 0 3px 3px 0 #eee;
  -moz-box-shadow: 0 3px 3px 0 #eee;
  box-shadow: 0 3px 3px 0 #eee;
}

#search-results li {
	padding: 5px;
}
#search-results li:focus {
	background-color: #e5e5e5;
	outline: none;
}
.hide-me {
	visibility: hidden;
}