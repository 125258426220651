ul.search-results {
  list-style: none;
  padding: 0;
}

ul.search-results li {
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid;
}

ul.search-results li a {
  text-decoration: none;
  color: #000;
}

ul.search-results li:hover {
  background-color: #cdcdcd;
}