#map-container,
#map {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: relative;
}

@keyframes spin {
  100% { transform:rotate(45deg) }
}