#directions-form {
	width: 100%;
	margin-top: 20px;
	padding-bottom: 5px;
	border-bottom: 2px solid #bbb;
}

#directions-form button {
	background-color: transparent;
}

#directions-form button.direction-type {
	padding: 5px;
	margin-bottom: 0;
	margin-top: 10px;
}
button::-moz-focus-inner {
	border: 0;
}

#directions-form button.swap-img {
	float: right;
	margin: 40px 5px;
	padding: 0;
}
#directions-form button img {
	padding: 0;
}

.inputs {
	width: 240px;
	display: inline-block;
}

#directions-form label span {
	display: inline-block;
	color: #666;
	width: 50px;
	padding-top: 5px;
}
#directions-form label {
	display: block;
	margin-top: 10px;
	line-height: 14px;
	width: 100%;
	border-bottom: 2px solid #bbb;
}

#directions-form input {
	border: none;
	padding: 5px;
	width: 180px;
	display: inline-block;
}