#directionButton, #directionButtonGoogle {
  cursor: pointer;
  margin: 10px 10px 10px 0;
  margin-right: 10px;
  height: 36px;
  border-radius: 10px;
  border: solid 1px #979797;
  background-color: #0577b1;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -0.1px;
}
#directionButton:active,
#directionButtonGoogle:active,
#directionButton:hover,
#directionButtonGoogle:hover,
#directionButton:focus,
#directionButtonGoogle:focus {
  background-color: #012169;
}
