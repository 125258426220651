.leg {
	margin: 30px auto;
}
.direction-leg-details p {
	font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #262626;
}
.direction-leg-details .footing span.distance {
	font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #666666;
  float: left;
  margin-right: 10px;
}
.direction-leg-details .footing hr {
	position: relative;
	top:7px;
}