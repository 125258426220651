.collapsed {
  visibility: hidden;
  display: none;
}
.expanded {
  display: block;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
#location-expandable button:hover {
  background-color: #efeff0;
}
#location-expandable button {
  min-height: 60px;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  cursor: pointer;
  background-color: transparent;
  color: #262626;
  font-size: 16px;
  border-bottom: 1px solid #efeff0;
}
#location-expandable div:first-child {
  border-top: 1px solid #efeff0;
}
#location-expandable button[aria-expanded="true"] {
  background-color: #efeff0;
}

#location-expandable button img {
  max-width: 18px;
  max-height: 30px;
  padding: 0 10px 0 0;
}

#location-expandable.building button span {
  margin-top: 12px;
}
