.duke__control {
  border: none !important;
}
.duke__value-container {
  padding:0;
}
.duke__indicators {
  max-width: 30px;
  margin-right: 15px;
}
.duke__value-container--has-value {}