#overlay {
  overflow-y: scroll;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  touch-action: none;
  user-select: none;
  display: block;
  background: #fff;
  position: absolute;
  top: 10%;
  width: 100%;
  height: 100%;
}

button {
  background-color: #00009c;
  border: none;
  padding: 10px 20px;
  margin: 20px 0;
  color: #fff;
  font-size: .9em;
  text-transform: uppercase;
}

#drag-me {
  margin: 0 auto;
  width: 100%;
  color: #bbb;
  outline: none;
  background-color: transparent;
}

.hidden {
  display: none;
}

.close-icon2 {
  margin: 0;
  display:block;
  box-sizing:border-box;
  width:32px;
  height:32px;
  border: none;
  background-color: transparent;
  transition: all 0.3s ease;
  position: absolute;
  top: 25px;
  right: 15px;
  cursor: pointer;
}

.close-icon2:hover {
  outline: solid;
}

@media (min-width: 768px) {
  .close-icon2 {
    background: -webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%);
  }

  #overlay {
    display: block;
    background: #fff;
    position: absolute;
    top: 65px;
    width: 320px;
    left: 15px;
    height: 92%;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  }
}
