#search {
  position: absolute;
  z-index: 1;
  top:20px;
  margin: 0 auto;
  width: 80%;
  left: 10%;
}

#hamburger {
  height: 50px;
  width: 50px;
  margin: 0;
  float: left;
}

#search-box {
  display: inline-block;
  height: 50px;
  width: 100%;
  background-color: #fafafa;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}
#search-box button {
  margin: 0;
  margin-right: 10px;
  height: 50px;
  width: 50px;
  background-color: transparent;
  float: right;
  padding: 0;
  background-color: #fafafa;
}
#search-box button.search-mag {
  width: 40px;
}
#search-box button.search-mag img {
  padding-left: 10px;
}
#search button.menu {
  margin:0;
  float: left;
  background-color: #071957;
}
#search button.menu:hover, #search button.menu:focus {
  background-color: #0577b1;
}
#search-box button img {
  height: 24px;
}
#search-box input {
  height: 30px;
  color: #666666;
  background-color: #fafafa;
  border: none;
  padding: 1px 10px;
  margin: 10px 5px;
  width: calc(100% - 110px);
}

/* placeholder text white */
::-webkit-input-placeholder {
  color: #666666;
  font-size: 16px;
}
::-moz-placeholder {
  color: #666666;
  font-size: 16px;
}
:-ms-input-placeholder {
  color: #666666;
  font-size: 16px;
}
:-moz-placeholder {
  color: #666666;
  font-size: 16px;
}

/* hide the scroll bar */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #FF0000;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  width: 10px;
  margin-top:-15px;
  height: 10px;
  color:#fafafa;
}
input[type="search"] {
  /*-webkit-appearance: none;*/
  /*background-color: green;*/
}

input, select, textarea {
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  #search {
    width: 320px;
    left: 15px;
    top: 15px;
  }
  #search-box input {
    width: 205px;
  }
}
