#mobile-app-cta {
  position: fixed;
  z-index: 999;
  background: #fff;
  top: 35%;
  width: 250px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 20px 20px 0;
}

#mobile-app-cta button {
  float: right;
  background-color: transparent;
  color: #0577b1;
  line-height: 16px;
  font-size: 13px;
  border:none;
}

#mobile-app-cta button:active {
  background-color: rgba(5, 119, 177, 0.2);
}
